const domain = 'console.careconnect-uat.netsmartexchange.com';
const apiDomain = 'careconnect-uat.netsmartexchange.com';

export const environment = {
  apiUrl: `https://${apiDomain}/api`,
  baseUrl: `https://${domain}`,
  oktaConfig: {
    clientId: `0oa1s6mu4klxpyJtk1d8`,
    issuer: `https://ntstcareconnect.okta.com`,
    redirectUri: `https://${domain}/oidc/callback`,
    postLogoutRedirectUri: `https://${domain}/loggedout`,
  },
};
